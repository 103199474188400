<template>
  <l-map
    :zoom="18"
    :center="[defaultLocation.lat, defaultLocation.lng]"
    @dblclick="onMapClick"
    ref="map"
  >
    <l-tile-layer
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
    />
    <l-marker
      v-if="position.lat && position.lng"
      visible
      draggable
      :icon="icon"
      :lat-lng.sync="position"
      @dragstart="dragging = true"
      @dragend="dragging = false"
    >
      <!-- <l-tooltip :content="tooltipContent" :options="{ permanent: true }" /> -->
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import axios from 'axios'
import { icon } from 'leaflet'
export default {
  props: {
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: -6.227373990352365,
        lng: 106.83368453847707,
      }),
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // LTooltip,
  },
  data() {
    return {
      loading: false,
      userLocation: {},
      icon: icon({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      }),
      position: {},
      address: '',
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      dragging: false,
    }
  },
  mounted() {
    this.getUserPosition()
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getNewAddress()
        this.$emit('change', { position: value, address: this.address })
      },
    },
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return '...'
      if (this.loading) return 'Loading...'
      return `<strong>${this.address.replace(
        ',',
        '<br/>',
      )}</strong> <hr/><strong>lat:</strong> ${
        this.position.lat
      }<br/> <strong>lng:</strong> ${this.position.lng}`
    },
  },
  methods: {
    async getNewAddress() {
      this.loading = true
      let address = 'Unresolved address'
      try {
        const { lat, lng } = this.position
        const { data: { results } } = await axios.get('https://trueway-geocoding.p.rapidapi.com/ReverseGeocode', {
          headers: {
            'x-rapidapi-key': 'd5942df4d4mshb278ecbbd1999a8p1d84a6jsn146c68306430',
            'x-rapidapi-host': 'trueway-geocoding.p.rapidapi.com',
          },
          params: {
            location: `${lat},${lng}`,
            language: 'en',
          },
        })
        // console.log('results', results)
        address = results[0].address
      } catch (error) {
        console.error('Reverse Geocode Error-> ', error)
      }
      this.loading = false
      return address
    },
    async getOldAddress() {
      this.loading = true
      let address = 'Unresolved address'
      try {
        const { lat, lng } = this.position
        const result = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)
        if (result.status === 200) {
          const body = await result.json()
          address = body.display_name
        }
      } catch (e) {
        console.error('Reverse Geocode Error->', e)
      }
      this.loading = false
      return address
    },
    onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng
    },
    getUserPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          // console.log('current location', this.userLocation)
        })
      }
    },
  },
}
</script>

<style>
</style>
